import { getBackendSrv } from '@grafana/runtime';
import { SelectableValue } from '@grafana/data';
import { Button, TextArea, Label, useStyles2, Select } from '@grafana/ui';
import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import { useAppState } from 'app-context/AppStateContext';
import {
  URL_SAVE_JSON,
  WRONG_MAC_ADDRESS_FORMAT,
  NO_MAC_ADDRESS,
  TEMPERATURES_OPTIONS,
  MAC_ADDRESS_PREFIX,
  TEXT_AREA_PLACEHOLDER,
  MAC_ADDRESS_LENGTH,
  LENGTH_OPTIONS,
  MM_S,
  CELSIUS,
  URL_ISO_CLASSES,
} from 'utils/constants';
import { LengthUnit, NewdashboardsProps, TemperatureUnit } from 'types/types';
import { CreateDashboardsSummary } from './CreateDashboardsSummary';
import { getNewJsonFilled } from 'utils/getNewJsonFilled';
import { CreateDashboardsHeader } from './CreateDashboardsHeader';
import { getJson } from 'utils/fetchNewJson';

interface CreateDashboardsContainerProps {}

export const CreateDashboardsContainer: React.FunctionComponent<CreateDashboardsContainerProps> = () => {
  const { dispatch, state } = useAppState();
  const { lang, dashboardsCreated, isoClassifications, datasourceJson } = state;
  const [macAddresses, setMacAddresses] = useState('');
  const [error, setError] = useState('');
  const [temperatureUnit, setTemperatureUnit] = useState<SelectableValue>({
    value: TemperatureUnit.celsius,
    label: CELSIUS,
  });
  const [lengthUnit, setLengthUnit] = useState<SelectableValue>({ value: LengthUnit.meter, label: MM_S });
  const styles = useStyles2(getStyles);

  const WRITE_API_URL = `api/datasources/proxy/uid/${datasourceJson.uid}`;
  const IDENTITY_CARD_ENDPOINT = `${WRITE_API_URL}/identity_card`;

  const handleMacAddresses = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setMacAddresses(e.currentTarget.value);
  };

  const getIsoClasses = async () => {
    const isoClasses = await getJson(dispatch, URL_ISO_CLASSES);
    if (!isoClasses) {
      console.log('Iso classifications file not found');
      return;
    }
    dispatch({ type: 'SET_ISO_CLASSIFICATIONS', payload: isoClasses });
  };

  /**
   * Creation of dashboards with default parameters
   */

  const createDashboards = async () => {
    dispatch({ type: 'SET_DASHBOARDS_CREATED', payload: [] });
    setError('');

    if (!macAddresses) {
      setError(NO_MAC_ADDRESS);
      return;
    }

    // Split mac addresses by lines break (mac addresses are copied from an excel sheet)
    const splitMacAddresses = macAddresses.split('\n');
    let dashboardTobeCreated = [] as NewdashboardsProps[];

    for (const splitMacAddress of splitMacAddresses) {
      if (!splitMacAddress) {
        continue;
      }

      const macAddress = splitMacAddress?.toLowerCase().trim();
      if (macAddress.length !== MAC_ADDRESS_LENGTH || !macAddress?.toUpperCase()?.includes(MAC_ADDRESS_PREFIX)) {
        setError(WRONG_MAC_ADDRESS_FORMAT);
        continue;
      }

      dashboardTobeCreated.push({ title: macAddress, url: '', creationStatus: 'processing' });

      const newJson = await getNewJsonFilled(
        lang,
        macAddress,
        temperatureUnit,
        lengthUnit,
        dispatch,
        isoClassifications
      );

      const indexOfDash = dashboardTobeCreated.findIndex((dash) => dash.title === macAddress);

      const payloadCreation = {
        machine_name: macAddress,
        mac_address: macAddress,
        process_function: 'Compressor',
        orientation: '- ↓ - ⟳',
        type: 'Motor',
        machine_class_longname: 'Class I',
        analysis_profile: 'Rotating',
        tags: 'Machine',
      };

      await getBackendSrv()
        .post(IDENTITY_CARD_ENDPOINT, payloadCreation, {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async () => {
          await getBackendSrv()
            .post(URL_SAVE_JSON, newJson) // send dashboard to grafana
            .then((result) => {
              dashboardTobeCreated[indexOfDash].creationStatus = 'SUCCESS';
              dashboardTobeCreated[indexOfDash].url = result.url;
              dispatch({ type: 'SET_DASHBOARDS_CREATED', payload: dashboardTobeCreated });
            })
            .catch(async (error: any) => {
              // delete dashboard from db if saving dashboard into grafana fails
              await getBackendSrv().delete(
                IDENTITY_CARD_ENDPOINT,
                { mac_address: macAddress },
                {
                  responseType: 'text',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );
              console.log(error);
              dashboardTobeCreated[indexOfDash].creationStatus = 'ERROR';
              dispatch({ type: 'SET_DASHBOARDS_CREATED', payload: dashboardTobeCreated });
            });
        })
        .catch((error: any) => {
          console.log(error);
          dashboardTobeCreated[indexOfDash].creationStatus = 'ERROR';
          dispatch({ type: 'SET_DASHBOARDS_CREATED', payload: dashboardTobeCreated });
        });
    }
  };

  const reset = () => {
    setError('');
    setMacAddresses('');
    dispatch({ type: 'SET_DASHBOARDS_CREATED', payload: [] });
  };

  useEffect(() => {
    dispatch({ type: 'GET_NEW_JSON', payload: {} });
    getIsoClasses();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <CreateDashboardsHeader />
      <div style={{ height: '25px', color: 'red' }}>{error}</div>
      <div style={{ display: 'flex', marginBottom: '15px' }}>
        <div>
          <Label>Temperature unit :</Label>
          <Select
            options={TEMPERATURES_OPTIONS}
            width={30}
            onChange={(v) => setTemperatureUnit(v)}
            value={temperatureUnit.value}
          />
        </div>
        <div style={{ marginLeft: '12px' }}>
          <Label>Length unit :</Label>
          <Select options={LENGTH_OPTIONS} width={30} onChange={(v) => setLengthUnit(v)} value={lengthUnit.value} />
        </div>
      </div>
      <div>
        <Label>List of mac addresses :</Label>
        <TextArea
          value={macAddresses}
          onChange={(e) => handleMacAddresses(e)}
          rows={15}
          placeholder={TEXT_AREA_PLACEHOLDER}
          style={{ width: '60%', maxHeight: '500px' }}
        />
      </div>
      <div>
        <Button variant={'secondary'} size={'lg'} onClick={reset} style={{ margin: '10px 5px 10px 0' }}>
          RESET
        </Button>
        <Button size={'lg'} onClick={() => createDashboards()} style={{ margin: '10px 0' }}>
          CREATE DASHBOARDS
        </Button>
      </div>
      {dashboardsCreated.length !== 0 && (
        <div className={styles.createdDashboardsContainer}>
          {dashboardsCreated.map((dash) => (
            <CreateDashboardsSummary dash={dash} key={dash.url} />
          ))}
        </div>
      )}
    </div>
  );
};

const getStyles = () => {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 25px;
      margin-top: 25px;
    `,
    headerContainer: css`
      display: flex;
      width: 100%;
      align-items: center;
    `,
    title: css`
      margin-left: 15px;
      font-size: 25px;
      font-weight: 500;
    `,
    createdDashboardsContainer: css`
      max-height: 250px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      border: 1px solid #e8e8e8;
      width: 60%;
    `,
  };
};
